import axios from 'axios'

const mainstemAuthToken = window.localStorage.getItem('mainstem_user_token')

// LogRocket Session URL
const sessionURL = window.localStorage.getItem('logrocket_session_url')

export const baseURL = import.meta.env.VITE_REACT_APP_BASE_API_URL
export const apiURL = '/api/auth/'

export const headers = {
  Authorization: `Bearer ${mainstemAuthToken || ''}`,
  'X-SessionURL': sessionURL,
  'X-Platform': 'Login MicroApp'
}

function logUnsuccessfulResponses(response) {
  return response
}

// Set Axios defaults
const HTTP = axios.create({
  baseURL: baseURL + apiURL,
  headers: headers
})
HTTP.interceptors.response.use(logUnsuccessfulResponses)

// Set Axios defaults
const HTTPBase = axios.create({
  baseURL: baseURL,
  headers: headers
})
HTTPBase.interceptors.response.use(logUnsuccessfulResponses)

export const userPermissionError =
  'Your user lacks permissions to access these controls, please contact your manager if you would like to gain access.'

export const colors = [
  '#01b5e2',
  '#440099',
  '#e63757',
  '#39afd1',
  '#fd7e14',
  '#02a8b5',
  '#727cf5',
  '#6b5eae',
  '#ff679b',
  '#f6c343'
]

export const themeColors = {
  primary: '#01b5e2',
  secondary: '#440099',
  success: '#00d27a',
  info: '#27bcfd',
  warning: '#f5803e',
  danger: '#e63757',
  light: '#f9fafd',
  dark: '#0b1727'
}

export const grays = {
  white: '#fff',
  100: '#f9fafd',
  200: '#edf2f9',
  300: '#d8e2ef',
  400: '#b6c1d2',
  500: '#9da9bb',
  600: '#748194',
  700: '#5e6e82',
  800: '#4d5969',
  900: '#344050',
  1000: '#232e3c',
  1100: '#0b1727',
  black: '#000',
  red: '#056i9567'
}

export { HTTP, HTTPBase }
