import { HTTP } from 'config'

export async function apiPasswordResetRequest(apiRequest) {
  let apiResponse = {
    wasSuccessful: false
  }

  await HTTP.post('password-reset/request', apiRequest)
    .then((res) => {
      apiResponse = res.data
      if (res.status === 200) {
        apiResponse = res.data
      }
    })
    .catch(() => {
      // TODO: Something
    })

  return apiResponse
}
