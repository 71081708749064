// CSS
import 'bootstrap/dist/css/bootstrap.min.css'
import '@mainstem/mainstem-react-app/dist/index.css'
import './assets/scss/layout.scss'
import 'assets/scss/utilities.scss'
// 3rd Party Packages
import { Fragment, useState, useEffect, useCallback } from 'react'
import { MainStemBase } from '@mainstem/mainstem-react-app'
import { BrowserRouter } from 'react-router-dom'
// api's
import { apiAuthLogout } from 'api/auth/auth-logout'
import { apiStatusCheck } from 'api/statusCheck/statusCheck'
import { apiPasswordResetRequest } from 'api/auth/auth-password-reset-request'
// App Config
import { Routes } from 'routes'
import { AppContext } from 'context'
import { apiURL, baseURL } from 'config'
import axios from 'axios'

const Layout = () => {
  // Set the baseURL in local storage for use in @mainstem/react-mainstem
  window.localStorage.setItem('baseURL', baseURL)

  const [firstLoad, setFirstLoad] = useState(true)
  const [checkedForInitialUser, setCheckedForInitialUser] = useState(false)
  const [loggedInUser, setLoggedInUser] = useState(null)
  const [userToken, setUserToken] = useState()
  const [permissions, setPermissions] = useState(false)

  const contextValue = {
    loggedInUser,
    setLoggedInUser,
    userToken,
    setUserToken,
    permissions,
    setPermissions
  }

  const handleStatusCheck = () => {
    apiStatusCheck().then((apiResponse) => {
      console.log(apiResponse)
    })
  }

  const handleGetCurrentUser = useCallback(() => {
    setCheckedForInitialUser(true)
  }, [])

  const handleLogin = (token) => {

    const headers = {
      Authorization: `Bearer ${token || ''}`,
      'X-Platform': 'Login MicroApp'
    }

    const HTTP = axios.create({
      baseURL: baseURL + apiURL,
      headers: headers
    })

    HTTP.get('current-user')
      .then((res) => {
        const apiResponse = res.data
        if (res.status === 200 && apiResponse.wasSuccessful) {
          if (apiResponse.appURL) {
            const url = `${apiResponse.appURL}/impersonate/${token}`
            window.location.href = url
          }
        }
      })
      .catch(() => {
        // TODO: Something
      })
  }

  const handleLogout = () => {
    apiAuthLogout()
    setLoggedInUser(null)
  }

  const appSettings = {
    showNavLeft: false,
    navLeft: null,
    navTop: null,
    routes: <Routes />,
    impersonationComponent: null,
    links: null,
    forceLogin: true,
    onLogin: handleLogin,
    onLogout: handleLogout,
    loggedInUser,
    loginContent: null,
    loginLogo: null,
    checkedForInitialUser,
    affiliateUUID: null,
    userToken,
    baseURL,
    resetPasswordRequestAPI: apiPasswordResetRequest
  }

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false)
      handleGetCurrentUser()
      handleStatusCheck()
    }
  }, [firstLoad, handleGetCurrentUser])

  return (
    <Fragment>
      <AppContext.Provider value={contextValue}>
        <BrowserRouter>
          <MainStemBase settings={appSettings} />
        </BrowserRouter>
      </AppContext.Provider>
    </Fragment>
  )
}

export default Layout
