// Routing
import { Redirect, Route, Switch } from 'react-router-dom'

export const Routes = () => (
  <Switch>
    <Route exact path='/'>
      <Redirect to='/login' />
    </Route>
  </Switch>
)
