// React
import { render } from 'react-dom'
// LogRocket
import LogRocket from 'logrocket'
// Global - Layout
import Layout from 'layout/'

LogRocket.init(import.meta.env.VITE_REACT_APP_LOGROCKET_PURCHASE_PRO_APP_ID)

// Set session URL for use later
LogRocket.getSessionURL((sessionURL) => {
  window.localStorage.setItem('logrocket_session_url', sessionURL)
})

render(<Layout />, document.getElementById('root'))
